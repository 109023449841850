import React, { Component }from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Logo from "../components/homepage/logo"
import Links from "../components/homepage/links"
import UserIndexForm from '../components/userforms/userform-main'
import AOS from 'aos'

class IndexPage extends Component {
  
  componentDidMount() {
    AOS.init({
      duration: 1000,
    })
  }

 render() {
   return (
     <Layout>
       <SEO title="Home" />
       <div className="grid-container" >
         <section className="home-content map-background" data-aos="fade-in">
           <Logo 
            title="Australia&YOU"
           />
           <div className="typography narrow-width">
             <p>Australia&YOU is a tool developed by the University of Melbourne using data from the Household Income and Labour Dynamic
            in Australia (HILDA) survey for you to explore how you compare with other Australians.</p>
           </div>
           <Links />
         </section>
       </div>
       <div className="arrow-background arrow-background--blue">
         <div className="grid-container form-index">
           <div className="user-fotm narrow-width">
             <h3 className="text-shadow fwbold text-center font-light">Enter your details</h3>
             <UserIndexForm />
           </div>
         </div>
       </div>
     </Layout>
   )
 } 
}

export default IndexPage

