import React, { Component } from "react"
import videosvg, { ReactComponent as Vid } from "../../images/Icons/video.svg"
import reportsvg, { ReactComponent as Report } from "../../images/Icons/report.svg"
import factssvg, {ReactComponent as Facts} from "../../images/Icons/fastfacts.svg"
import { Link, animateScroll as scroll } from "react-scroll";
import Modal from 'react-responsive-modal';
class Links extends Component {
    
    state = {
        openModal: false
    }

    handleOpenClose = (type, open) => this.setState({ [`open${type}`]: open });
    handleOpen = () => this.handleOpenClose('Modal', true);
    handleClose = () => this.handleOpenClose('Modal', false);

    render() {
        const { openModal } = this.state
        const bg = {
            overlay: { background: 'rgb(18, 49, 140, 0.7)' },
            modal: {
                background: '#FFE1D8',
                width: 600,
                padding: 50
            }
        };
        return (
            <div className="home-content__actions">
                <div data-aos="zoom-up">
                    <Link 
                        className="animated pulse delay-2s button secondary has-radius is-main"
                        activeClass="is-active"
                        to={'user-1'}
                        spy={true}
                        smooth={true}
                        offset={-100}
                        duration={500}
                    >Let's get started</Link>
                </div>
                <div className="home-content__modals"> 
                    <div className="grid-x grid-margin-x">
                        {/* <div className="cell medium-4">
                            <a className="modal-link">
                                <span><img src={videosvg} alt="Video" /></span>
                                <span>Watch the video</span>
                            </a>
                        </div> */}
                        <div className="cell medium-6">
                            <a href="https://melbourneinstitute.unimelb.edu.au/__data/assets/pdf_file/0011/3127664/HILDA-Statistical-Report-2019.pdf" target="_blank" className="modal-link">
                                <span><img src={reportsvg} alt="Report" /></span>
                                <span>View the report</span>
                            </a>
                        </div>
                        <div className="cell medium-6">
                            <a onClick={this.handleOpen} className="modal-link">
                                <span><img src={factssvg} alt="Facts" /></span>
                                <span>Fast facts</span>
                            </a>
                        </div>
                    </div>
                </div>
                <div className="typography narrow-width">
                    <p>The University of Melbourne will not retain or share any <a href="http://policy.unimelb.edu.au/MPF1104">personal details</a> entered in this tool.</p>
                </div>

                <Modal onClose={this.handleClose} open={openModal} styles={bg}>
                    <div className="fastfacts">
                        <h3 className="text-shadow blue text-center">Fast facts</h3>
                        <div className="typography narrow-width">
                            <p>Before we start, here’s a breakdown of the HILDA Survey and what you can expect from this tool.</p>
                            <b>Fast Facts:</b><br/>
                            <ul>
                                <li>The HILDA Survey follows the lives of more than 17,000 Australians each year.</li>
                                <li>It collects information on many aspects of life in Australia, including household and family relationships, income and employment, and health and education.</li>
                                <li>Participants are followed over the course of their lifetime.</li>
                                <li>HILDA is the only study of its kind in Australia.</li>
                            </ul>
                        </div>
                    </div>
                </Modal>
            </div>
        )
    }
};


export default Links