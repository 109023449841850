import React, { Component } from "react";
import SaveUser from '../actions/save-user'
import Modal from 'react-responsive-modal';
import { Animated } from "react-animated-css";

export default class UserFormMain extends Component {
    constructor() {
        super();
        this.handleSubmit = this.handleSubmit.bind(this);
        this.state = {
            open: false,
        }
    }

    onOpenModal = () => {
        this.setState({ open: true });
    };

    onCloseModal = () => {
        this.setState({ open: false });
    };
    
    handleSubmit = event => {
        event.preventDefault()
        const saved = SaveUser(event.target.id)

        if(!saved) {
            this.onOpenModal();
        } else  {
            if(typeof window !== "undefined") {
                window.location.href = '/results'
            }
        }
    }
    

    render() {
        const { open } = this.state
        const bg = {
            overlay: { background: 'rgb(18, 49, 140, 0.7)' },
            modal: {
                background: '#FFE1D8',
                width: 500,
            }
        };
        return (
            <>
            <Modal onClose={this.onCloseModal} open={open} styles={bg} >
                <div className="no-data-found">
                    <h2 class="text-shadow fwbold white">Sorry!</h2>
                    <p>We couldn't find results for you.</p>
                </div>
            </Modal>
               
            <form onSubmit={this.handleSubmit} id="user-1" >
                <div className="user-form__age">
                    <h4 className="user-form__title text-shadow">Age</h4>
                    <div className="radio-buttons three-column">
                        <div className="radio-button">
                            <label htmlFor="15-24" >
                                <input id="15-24" name="age_group" type="radio" value="15-24" title="15-24" />
                                <span className="box">15-24</span>
                            </label>
                        </div>
                            <div className="radio-button" >
                            <label htmlFor="25-34">
                                <input id="25-34" name="age_group" type="radio" value="25-34" title="25-34" />
                                <span className="box">25-34</span>
                            </label>
                        </div>
                        <div className="radio-button">
                            <label htmlFor="35-44">
                                <input id="35-44" name="age_group" type="radio" value="35-44" title="35-44" />
                                <span className="box">35-44</span>
                            </label>
                        </div>
                        <div className="radio-button">
                            <label htmlFor="45-54">
                                <input id="45-54" name="age_group" type="radio" value="45-54" title="45-54" />
                                <span className="box">45-54</span>
                            </label>
                        </div>
                        <div className="radio-button">
                            <label htmlFor="55-64">
                                <input id="55-64" name="age_group" type="radio" value="55-64" title="55-64" />
                                <span className="box">55-64</span>
                            </label>
                        </div>
                        <div className="radio-button">
                            <label htmlFor="65-74">
                                <input id="65-74" name="age_group" type="radio" value="65-74" title="65-74" />
                                <span className="box">65-74</span>
                            </label>
                        </div>
                    </div>
                </div>
                <div className="user-form__gender">
                    <h4 className="user-form__title text-shadow">Gender</h4>
                    <div className="radio-buttons two-column user-form__gender-options">
                        <div className="radio-button">
                            <label>
                                <input id="male" name="sex" type="radio" value="M" title="Male" />
                                <span className="box">Male</span>
                            </label>
                        </div>
                        <div className="radio-button">
                            <label>
                                <input id="female" name="sex" type="radio" value="F" title="Female" />
                                <span className="box">Female</span>
                            </label>
                        </div>
                        {/* <div className="radio-button">
                            <label>
                                <input id="non-binary" name="sex" type="radio" value="N" title="Non-Binary" />
                                <span className="box">Non-Binary</span>
                            </label>
                        </div>
                        <div className="radio-button">
                            <label>
                                <input id="undisclosed" name="sex" type="radio" value="U" title="Undisclosed" />
                                <span className="box">Undisclosed</span>
                            </label>
                        </div> */}
                    </div>
                </div>
                <div className="user-form__education">
                    <h4 className="user-form__title text-shadow">Level of Education</h4>
                    <select name="education" id="education">
                        <option value="OPS">Post school diploma or Cert 3 or 4</option>
                        <option value="Deg">Bachelor Degree, or higher</option>
                        <option value="CHS">Completed High School</option>
                        <option value="NCHS">Did not complete High School</option>
                        <option value="PG">Postgraduate Degree</option>
                    </select>
                </div>
                <div className="user-form__household">
                    <h4 className="user-form__title text-shadow">Household</h4>
                    <select name="household_type" id="household">
                        <option value="Coup">Couple</option>
                        <option value="Coupdep">Couple with dependent children</option>
                        <option value="Sparent">Single Parent with dependent children</option>
                        <option value="Other">Other</option>
                    </select>
                </div>
                <div className="user-form__submit text-center">
                    <button type="submit" className="button secondary has-radius submit-button">Submit</button>
                </div>
            </form>
            </>
        )
    }
}
